import React from "react"
import styled from "@emotion/styled"
import { FormattedMessage } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>
        <FormattedMessage id="404.title" />
      </h1>
    </Wrapper>
  </Layout>
)

export default NotFoundPage

const Wrapper = styled.div({
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  height: `100vh`,
})
